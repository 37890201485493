article#homepage{
  height: 100vh;
  flex-flow: column;

  @media @max768 {
    height: calc(100vh - 42px);
  }

  article#description-home{
    flex: 0 1 auto;

    @media @max768 {
      display: none;
    }

    em{
      color: @jl-color;
    }
  }

  article#slideshow-home{
    flex: 1 1 auto;

    @media @max768 {
      flex-direction: column;
    }

    & > div{
      overflow: hidden;
      position: relative;
      &#slideshow-left{
        margin-right: 10px;
        cursor: w-resize;
        width: calc(30% - 1px);
        @media @max768 {
          width: 100%;
          height: 42.5vh;
          margin-right: 0;
        }
      }
      &#slideshow-right{
        margin-left: 10px;
        cursor: e-resize;
        width: calc(30% - 1px);
        @media @max768 {
          width: 100%;
          height: 42.5vh;
          margin-left: 0;
        }
      }
      &#slidehome-count{
        width: calc(40% + 2px);
        padding-left: 20%;
        justify-content: space-between;
        align-items: flex-end;
        font-size: 3.2vw;
        line-height: 2vw;

        &.col-1{
          @media @max768 {
            width: 100%;
            height: 15vh;
            padding-left: 0;
            font-size: 30px;
            line-height: 25px;
          }
        }
      }

      div.outerslide{
        position: absolute;
        background-color: white;
        transform: translateX(100%);
        z-index: 1;
        transition: all .5s cubic-bezier(.215, .61, .355, 1);
        div {
          background-position: center;
          background-repeat: no-repeat;
          background-origin: content-box;
        }
        &.activeSlide{
          transform: translateX(0);
          z-index: 2;
        }
        &.prevSlide{
          transform: translateX(-100%);
          z-index: 1;
        }
        &.nextSlide{
          transform: translateX(100%);
          z-index: 1;
        }
      }
    }

  }
}
