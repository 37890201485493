header#blog-header{
  height: calc(1.8vw + 40px);
  width: calc(100% - 60px);
  left: 60px;
  background: white;
  border-bottom: 1px solid @grey;
  transform: translateY(0);
  transition: transform .5s cubic-bezier(.215, .61, .355, 1);

  @media @max768 {
    display: none;
  }

  &.hidden{
    transform: translateY(-100%);
  }
}


article.blog-article{

  border-bottom: 1px solid @grey;

  header {

    h2{
     font-size: 3.2vw;
     line-height: 3.5vw;

     @media @max768, @min768 {
       font-size: 20px;
       line-height: 24px;
     }
    }

  }

  section.blog-article-text{

    img{
      max-width: 60%;
      max-height: 50vh;
      margin-top: 2vw;

      @media @max768 {
        max-width: 100%;
        margin-top: 12px;
      }
    }

    p, h3{
      margin-top: 2vw;
      width: 60%;

      @media @max768 {
        width: 100%;
        margin-top: 12px;
      }

      a{
        text-decoration: none;
        color: @blog-color;
      }

      a:hover{
        border-bottom: 1px solid @blog-color;
      }
    }

    h3{
      border-bottom: 1px solid black;
      width: fit-content;
    }

    em{
      color: @blog-color;
    }

    ul{
      list-style: none;
    }

  }

}
