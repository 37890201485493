article#contact-div{
  font-size: 3.2vw;
  line-height: 3.6vw;

  @media @max768, @min768 {
    font-size: 20px;
    line-height: 24px;
  }

  div#colophon, div#links{
    font-size: 1.2vw;
    line-height: 1.6vw;

    @media @max768, @min768 {
      font-size: 14px;
      line-height: 18px;
    }

    span:not(:last-of-type)::after {
      content: ', ';
      white-space: pre;
    }
  }

  a {
    text-decoration: none;
    color: black;
    &:hover{
      color: @jl-color;
    }
  }

  section#contact-left.col-3{
    @media @max768, @min768 {
      width: 100%;
    }
  }

  img.col-2{
    @media @max768 {
      width: 80%;
    }

    &.pl2{
      @media @max768 {
        padding-left: 0;
        padding-top: 18px;
        padding-bottom: 18px;
      }

      @media @min768 {
        padding-left: 0;
        padding-top: 36px;
        padding-bottom: 18px;
      }
    }
  }
}
