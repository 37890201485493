article#projects-slideshow{

  section#left-slideshow{

    height: 100vh;
    border-right: 1px solid @grey;
    position: relative;
    overflow: hidden;

    @media @max768 {
      height: calc(50vh - 21px);
      border-right: 0;
      border-bottom: 1px solid @grey;
    }

    &.col-3{
      @media @max768 {
        width: 100%;
      }
    }

    div.outerslide{
      position: absolute;
      background-color: white;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;

      &.activeSlide{
        opacity: 1;
        z-index: 2;
      }

      &.inactiveSlide{
        opacity: 0;
        z-index: 1;
      }

      div.singleimg{
        width: 100%;
        height: 100%;

        img{
          max-width: 100%;
          max-height: 100%;
          cursor: zoom-in;
          @media @max768 {
            cursor: default;
          }
        }
      }
    }

  }

  section#right-slideshow{

    height: 100vh;
    position: relative;

    @media @max768 {
      height: calc(50vh - 21px);
      display: flex;
      flex-direction: column;
    }

    &.col-2{
      @media @max768 {
        width: 100%;
      }
    }

    div.right-up {

      height: calc(100vh - 40px - 1.8vw);
      overflow-y: scroll;
      border-bottom: 1px solid @grey;
      position: relative;

      @media @max768 {
        height: calc(100% - 42px);
        border-bottom: 0;
        border-top: 1px solid @grey;
        order: 1;
      }

      &::webkit-scrollbar{
        display: none;
      }

      div.outerslide{
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
        width: 100%;
        display: none;

        &.activeSlide{
          display: block;
        }

        &.inactiveSlide{
          display: none;
        }

        h2.project-title{
          display: inline-block;
          border-bottom: 1px solid black;
          margin-bottom: 2vw;
          @media @max768 {
            margin-bottom: 18px;
          }
        }

        h4.project-medium{
          text-transform: lowercase;
          span:not(:last-of-type)::after{
            content: ', ';
            white-space: pre;
          }
        }

        h4.project-size{
          margin-bottom: 2vw;
          @media @max768 {
            margin-bottom: 18px;
          }
        }

        h5.copyright{
          font-size: 1.2vw;
          margin-bottom: 2vw;
          @media @max768 {
            font-size: 10px;
            margin-bottom: 18px;
          }
        }

        h4.project-vente{
          margin-bottom: 2vw;
          color: @jl-color;
          @media @max768 {
            margin-bottom: 18px;
          }
        }

        h4.project-description{
          p{
            margin-bottom: 2vw;
            @media @max768 {
              margin-bottom: 18px;
            }
          }

          figure{
            margin-bottom: 2vw;
            width: 80%;
            @media @max768 {
              margin-bottom: 18px;
            }
          }

          img{
            max-width: 100%;
          }

          iframe{
            width: 100%;
          }

          iframe{
            height: 17vw;
            @media @max768 {
              height: 35vh;
            }
          }

          a{
            text-decoration: none;
            color: @jl-color;
          }

          a:hover{
            border-bottom: 1px solid @jl-color;
          }

          em{
            color: @jl-color;
          }

          ul{
            list-style: none;
          }
        }

      }

    }

    div.right-bottom{
      justify-content: space-between;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      @media @max768 {
        order: 0;
      }

      span.slideNav{
        cursor: pointer;
        &:hover{
          color: @jl-color;

          @media @max768 {
            color: black;
          }
        }
      }
    }

  }

}

div#zoom-projects{
  pointer-events: none;
  min-width: 100%;
  min-height: 100%;
  background: rgba(255, 255, 255, 0.8);
  opacity: 0;
  align-items: center;
  justify-content: center;
  transition: all .3s cubic-bezier(.215, .61, .355, 1);
  &.active{
    pointer-events: all;
    opacity: 1;
    cursor: zoom-out;
  }

  @media @max768 {
    display: none;
  }

  img{
    max-width: 100%;
  }
}
