nav#left-menu {
  font-family: 'EuroNextMono', Courier, monospace;
  letter-spacing: 1.5px;
  font-size: 12px;
  line-height: 20px;
  border-right: 1px solid @grey;
  background-color: white;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  transition: all .5s cubic-bezier(.215, .61, .355, 1);
  overflow-x: hidden;

  @media @max768 {
    border-right: 0;
    border-bottom: 1px solid @grey;
    overflow: hidden;
    font-size: 10px;
  }

  ul {
    list-style: none;

    li{
      width: calc(14px + 60vw);
      transform: rotate(0);
      transform-origin: 9px center;
      &.menu-jl-item, &.menu-language-item{
        a, span{
          color: @jl-color;
        }
      }
      &.menu-graphic-item{
        a, span{
          color: @graphic-color;
        }
      }
      &.menu-blog-item{
        a, span{
          color: @blog-color;
        }
      }
      span.exp {
        font-size: 8px;
        vertical-align: top;
        position: relative;
        top: -4px;
        margin-left: 5px;
      }
    }

    &.xo0 li.title-menu{
      display: inline-block;
      transition: all .5s cubic-bezier(.215, .61, .355, 1);
      @media @max768 {
        width: calc(100% - 28px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &.small{
    width: 60px;

    @media @max768 {
      width: 100%;
      height: 42px;
    }

    ul li {
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s cubic-bezier(.215, .61, .355, 1);
    }

    div.text-icon{
      opacity: 0;
      transition: opacity .3s cubic-bezier(.215, .61, .355, 1);
    }

    .xo0 li.title-menu{
      opacity: 1;
      pointer-events: all;
      transform: rotate(90deg);

      @media @max768 {
        transform: rotate(0);
      }
    }
  }

  &.big{
    width: calc(24px + 60vw);

    @media @max768 {
      width: 100%;
      height: 100%;
    }

    ul li {
      width: 100%;
      opacity: 1;
      transition: opacity .3s cubic-bezier(.215, .61, .355, 1) .2s;
    }

    div.text-icon{
      opacity: 1;
      transition: opacity .3s cubic-bezier(.215, .61, .355, 1) .2s;
    }
  }

  a {
    text-decoration: none;
  }

  & > div{
    position: absolute;
    bottom: 20px;
    left: 20px;

    @media @max768 {
      display: none;
    }

    &#menu-blog{
      color: @blog-color;
    }

    &#menu-work.art{
      color: @jl-color;
      a{
        color: @jl-color
      }
      div#icon-line{
        background-color: @jl-color;
        &:before, &:after{
          background-color: @jl-color;
        }
      }
      div#icon-grid{
        color: @jl-color;
        background-color: @jl-color;
        box-shadow: 11px 0 0 0px @jl-color, 0 11px 0 0px @jl-color, 11px 11px 0 0px @jl-color;
      }
    }

    &#menu-work.graphic{
      color: @graphic-color;
      a{
        color: @graphic-color
      }
      div#icon-line{
        background-color: @graphic-color;
        &:before, &:after{
          background-color: @graphic-color;
        }
      }
      div#icon-grid{
        color: @graphic-color;
        background-color: @graphic-color;
        box-shadow: 11px 0 0 0px @graphic-color, 0 11px 0 0px @graphic-color, 11px 11px 0 0px @graphic-color;
      }
    }

    li{
      width: max-content;
      transform: rotate(0);
      transform-origin: 9px center;
      list-style: none;
      margin-top: 20px;
      cursor: pointer;

      div.text-icon{
        margin-left: 40px;
      }

      &.inactive{
        opacity: 0.2;
        transition: opacity .2s cubic-bezier(.215, .61, .355, 1);
        &:hover{
          opacity: 1;
        }
      }
    }

    div#icon-line{
      position: absolute;
      margin-left: 1px;
      margin-top: 8px;
      width: 18px;
      height: 2px;

      &:before{
        content: '';
        position: absolute;
        top: -7px;
        left: 0;
        width: 18px;
        height: 2px;
      }

      &:after{
        content: '';
        position: absolute;
        top: 7px;
        left: 0;
        width: 18px;
        height: 2px;
      }
    }

    div#icon-grid{
      margin-left: 1px;
      position: absolute;
      width: 8px;
      height: 8px;
    }

    div#icon-arrow{
      color: #000;
      position: absolute;
      margin-left: 10px;
      margin-top: 1px;
      width: 2px;
      height: 18px;
      background-color: @blog-color;

      &:before{
        content: '';
        position: absolute;
        left: -5px;
        top: 1px;
        width: 10px;
        height: 10px;
        border-top: solid 2px @blog-color;
        border-right: solid 2px @blog-color;
        -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
      }
    }
  }

  div#mobile-nav{
    display: none;

    @media @max768 {
      display: inline-block;
      position: fixed;
      top: 1rem;
      right: 1rem;
      width: 18px;
      height: 18px;
    }

    &.graphic, &.graphictemp{
      div#icon-close{
        background-color: @graphic-color;
        &:before, &:after{
          background-color: @graphic-color;
        }
      }
    }

    &.blog{
      div#icon-close{
        background-color: @blog-color;
        &:before, &:after{
          background-color: @blog-color;
        }
      }
    }

    div#icon-close{
      background-color: @jl-color;
      &:before, &:after{
        background-color: @jl-color;
      }
    }

    div#icon-close{
      position: absolute;
      margin-left: 1px;
      margin-top: 8px;
      right: 0;
      width: 18px;
      height: 2px;
      margin-right: 0px;
      transition: all .2s cubic-bezier(.215, .61, .355, 1);

      &:before{
        content: '';
        position: absolute;
        top: -6px;
        right: 0;
        width: 18px;
        height: 2px;
        transform: rotate(0);
        transition: all .2s cubic-bezier(.215, .61, .355, 1);
      }

      &:after{
        content: '';
        position: absolute;
        top: 6px;
        right: 0;
        width: 18px;
        height: 2px;
        transition: all .2s cubic-bezier(.215, .61, .355, 1);
      }

      &.active{
        width: 0px;

        &:before{
          transform: rotate(45deg);
          top: 0;
        }
        &:after{
          transform: rotate(-45deg);
          top: 0;
        }
      }
    }

  }
}
