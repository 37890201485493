article#biographie-div, article#curriculum-div{

  &.col-4{
    @media @max768, @min768 {
      width: 100%;
    }
  }

  p, h3{
    margin-bottom: 2vw;
    @media @max768 {
      margin-bottom: 18px;
    }
  }

  h3{
    border-bottom: 1px solid black;
    display: inline-block;
  }

  em{
    color: @jl-color;
  }

  ul{
    list-style: none;
  }

  figure{
    width: 50%;
    margin-bottom: 2vw;

    @media @max768 {
      width: 100%;
      margin-bottom: 18px;
    }

    img{
      max-width: 100%;
    }
  }

  #previous-websites {

    a{
      color: black;
      display: inline-block;
      text-decoration: none;
      float: none;

      &:hover{
        color: @jl-color;
      }
    }
  }

}
