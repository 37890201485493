article#art-div{

  section#art-grid{
    width: calc(80% + 9px);
    display: inline-block;
    opacity: 0;
    transition: all .3s cubic-bezier(.215, .61, .355, 1);

    @media @max768 {
      width: 100%;
    }

    @media @min768 {
      width: calc(75% - 20px);
    }

    &.grid-template{
      opacity: 1;
      div.outerdiv-grid{
        display: inline-block;
        float: left;
        height: 40vh;
        background: @jl-color;
        cursor: pointer;
        position: relative;
        margin-right: 20px;
        margin-bottom: 20px;

        @media @max768 {
          max-height: 50vw;
          max-width: 100%;
          margin-right: 12px;
          margin-bottom: 12px;
        }

        @media @min768 {
          max-height: 30vw;
          max-width: 100%;
        }

        img{
          height: 100%;
        }

        &.hidden{
          display: none;
        }

        div.project-title{
          color: white;
          padding: 10px 40px 10px 10px;
          position: absolute;
          opacity: 0;
          width: 100%;
          top: 0;

          @media @max768 {
            display: none;
          }
        }

        &:hover{
          img{
            opacity: 0;
            @media @max768 {
              opacity: 1;
            }
          }
          div.project-title{
            opacity: 1;
          }
        }
      }
    }

    &.line-template{
      opacity: 1;
      div.outerdiv-grid{
        display: inline-block;
        float: left;
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 3.2vw;
        margin-right: 20px;
        margin-bottom: 10px;

        img{
          height: 2.6vw;
          float: left;
          display: inline-block;
        }

        &.hidden{
          display: none;
        }

        div.project-title{
          float: left;
          color: black;
          position: absolute;
          margin-left: 6vw;
          font-size: 3.2vw;
          max-width: calc(100% - 6vw);
          line-height: 3.4vw;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover{
          div.project-title{
            color: @jl-color;
          }
        }

      }
    }

  }

  nav#filters{
    width: calc(20% - 20px);
    font-family: 'EuroNextMono', Courier, monospace;
    letter-spacing: 1.5px;
    font-size: 12px;
    line-height: 20px;
    border-left: 1px solid @grey;
    background-color: white;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    color: @jl-color;

    @media @max768 {
      display: none;
    }

    @media @min768 {
      width: 25%;
    }

    div.filtre, div#filters-title, div#all{
      border-bottom: 1px solid @grey;
      width: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    div#all{
      cursor: pointer;
    }

    label.container {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        float: left;
        position: relative;
        padding-left: 19px;
        cursor: pointer;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            &:checked ~ .checkmark {
              background-color: @jl-color;
            }
        }

        .checkmark {
            position: absolute;
            top: 4px;
            left: 0px;
            height: 8px;
            width: 8px;
            border-radius: 4px;
            background-color: white;
            border: 1px solid @jl-color;
        }

        &:hover input ~ .checkmark {
            background-color: @jl-color;
        }

    }

  }
}

div#img-hover{
  pointer-events: none;
  height: 40vh;

  @media @min768, @max768 {
    display: none;
  }

  img{
    height: 100%;
  }
}
