article.news-article{

  border-bottom: 1px solid @grey;
  display: inline-block;

  h2.news-article-text{

   p{
     margin-right: 40%;

     @media @max768, @min768 {
       margin-right: 10%;
     }
   }

   a{
     text-decoration: none;
     color: @jl-color;
   }

   a:hover{
     border-bottom: 1px solid @jl-color;
   }

   em{
     color: @jl-color;
   }

   ul{
     list-style: none;
   }

   figure, img{
     height: 300px;
     display: inline-block;
     float: left;
     margin-right: 20px;
     margin-top: 20px;

     @media @max768 {
       height: 50vw;
       max-width: 100%;
       margin-right: 12px;
       margin-top: 12px;
     }

     @media @min768 {
       max-width: 100%;
     }

     iframe{
       height: 300px;
       width: 533px;

       @media @max768 {
         height: 50vw;
         width: 88.83vw;
       }
     }
   }

  }

}
