@font-face {
    font-family: 'EuroNextMono';
    src: url('../fonts/EuroNext_Mono-50.woff2') format('woff2'), url('../fonts/EuroNext_Mono-50.woff') format('woff');
}

@font-face {
    font-family: 'VanillaSerif';
    src: url('../fonts/VanillaSerifLight_2018_06_10_2.woff2') format('woff2'), url('../fonts/VanillaSerifLight_2018_06_10_2.woff') format('woff');
}

@jl-color: #e04117;
@graphic-color: #034198;
@blog-color: #027939;
@grey: #e6e6e6;

@max768: (max-width: 767px), (orientation: portrait);
@min768: (min-width: 768px) and (max-width: 1100px);

@import "modules/less/base";

html{
  font-size: 125%;

  @media @max768 {
    font-size: 75%;
  }
}

body{
	font-family: 'VanillaSerif', Times, serif;
}

[class*="col-"] {
    float: left;
}

div.content{
  position: relative;
  font-size: 1.6vw;
  line-height: 2vw;

  @media @max768, @min768 {
    font-size: 14px;
    line-height: 18px;
  }

  &.ml3{
    @media @max768 {
      margin-left: 0;
      margin-top: 42px;
    }
  }
}


@import "modules/less/menu";
@import "modules/less/home";
@import "modules/less/blog";
@import "modules/less/actualites";
@import "modules/less/contact";
@import "modules/less/biographie";
@import "modules/less/art";
@import "modules/less/graphic";
@import "modules/less/projects";
